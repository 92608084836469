import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Download from './Download'
// import Particles from 'react-particles-js';


const Hero = ({ subtitle, t }) => (
  <StaticQuery query={graphql`
      query {
        bg: file(relativePath: { eq: "hero-bg.jpg" }) {
          childImageSharp {
            fluid(
              quality: 80,
              maxWidth: 2000
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        phone: file(relativePath: { eq: "phone.png" }) {
          childImageSharp {
            fluid(
              quality: 80,
              maxWidth: 800
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <StyledSections id="home"  className="row d-flex align-items-center">
          <BackgroundImage
            Tag="div"
            fluid={data.bg.childImageSharp.fluid}
            backgroundColor={`transparent`}
            objectFit="cover"
            style={{
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
              height: '900px',
              position: 'absolute',
              top: 0,
              bottom:0,
              left:0,
              right:0,
              zIndex: -1
            }}
          />
          <Wave style={{
            position: 'absolute',
            bottom:0,
            left:0,
            right:0,
            zIndex: -1
          }}/>
          <div className="col-md-5 offset-md-1">
            <div className=" text-center pt-xs-50 pb-xs-50">
              <h1>Enigmibot</h1>
              <h2 className="h5">{subtitle}</h2>
            </div>
            <Download t={t}/>
          </div>
          <div className="p-5 col-8 offset-2 col-sm-6 offset-sm-3 col-md-6 offset-md-0 col-lg-3 offset-lg-1">
            <Img alt={'Enigmibot'} fluid={data.phone.childImageSharp.fluid} />
          </div>
        </StyledSections>
      )
    }
    }
  />
)

const StyledSections = styled.section`
  min-height: 900px;
  overflow: hidden;
  color: white;
  position: relative;
`

const Wave = (props)=>(
  <span {...props} dangerouslySetInnerHTML={{__html: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 2353.6 1112.5" style="enable-background:new 0 0 2353.6 1112.5;" xml:space="preserve" class="svg replaced-svg">
      <style type="text/css">
          .st0{fill:#FFFFFF;}
      </style>
      <path class="st0" d="M2353.6,0v1112.5H0c132.1-171.4,350.7-231.4,655.9-179.9c474,79.9,637.1-23.3,882.7-262.4  C1802.7,413,1870,54.1,2353.6,0z"></path>
    </svg>
  `}} />
)
export default Hero 






/* <Particles
  style={{ position: 'absolute', top: 0, bottom: 0, left: 0, rigth: 0 }}
  params={{
    "particles": {
      "number": {
        "value": 80,
        "density": {
          "enable": true,
          "value_area": 800
        }
      },
      "color": {
        "value": "#ffffff"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 0,
          "color": "#000000"
        },
        "polygon": {
          "nb_sides": 5
        },
        "image": {
          "src": "img/github.svg",
          "width": 100,
          "height": 100
        }
      },
      "opacity": {
        "value": 0.5,
        "random": false,
        "anim": {
          "enable": false,
          "speed": 1,
          "opacity_min": 0.1,
          "sync": false
        }
      },
      "size": {
        "value": 3,
        "random": true,
        "anim": {
          "enable": false,
          "speed": 40,
          "size_min": 0.1,
          "sync": false
        }
      },
      "line_linked": {
        "enable": true,
        "distance": 150,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 6,
        "direction": "none",
        "random": false,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "repulse"
        },
        "onclick": {
          "enable": true,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 400,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 400,
          "size": 40,
          "duration": 2,
          "opacity": 8,
          "speed": 3
        },
        "repulse": {
          "distance": 200,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true
  }}
/> */