/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Header from "./header"
import {
  JSONLD,
  // Graph,
  // Product,
  // ItemReviewed,
  // Review,
  // Author,
  // Location,
  // Rating,
  Generic
} from 'react-structured-data';


import "../style/bootstrap.scss"

// import "./layout.css"
// import "./grid.css"
// import "../legacy/style/bootstrap.min.css"
// import "../legacy/style/responsive.css"
import "../legacy/style/main.css"
import "react-alice-carousel/lib/alice-carousel.css";

const Layout = ({ children, intl }) => {

  const t = (id) => intl.formatMessage({ id })
  return (
    <>
      <Header t={t} siteTitle={t('website_title')} />
      <div>
        <main>{children}</main>
        <footer>
          <div className="container">
            <div className="col-sm-12 tc">
              <p className="pt-xs-20 pb-xs-20 m-xs-0">
                © {new Date().getFullYear()} Obsidianweb LTD - Stefano Solinas - Obsidianart <br />
                <Link style={{ color: 'inherit'}} to='/privacy-policy/'>{intl.formatMessage({ id: 'privacy-policy' })}</Link>
              </p>
            </div>
          </div>
        </footer>
      </div>


      <JSONLD>
        <Generic
          type="MobileApplication"
          jsonldtype="MobileApplication"
          schema={{
            name: "Enigmibot",
            operatingSystem: "Android, IOs",
            applicationCategory: "Game",
            author: "Obsidian Web ltd",
            installUrl: "https://play.google.com/store/apps/details?id=com.enigmibot",
            // installUrl: "https://apps.apple.com/app/id1447476948",
          }}
          />
      </JSONLD>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default injectIntl(Layout)
