import React from "react"
import "react-alice-carousel/lib/alice-carousel.css"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

export const cardFluidImage = graphql`
  fragment cardFluidImage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Cards = ({ title, sub, imgTitle, imgAlt, t }) => {
  const { img1, img2, img3, img4, img5, img6, img7, img8, img9, img10 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "cards/1.png" }) {...cardFluidImage },
      img2: file(relativePath: { eq: "cards/2.png" }) {...cardFluidImage },
      img3: file(relativePath: { eq: "cards/3.png" }) {...cardFluidImage },
      img4: file(relativePath: { eq: "cards/4.png" }) {...cardFluidImage },
      img5: file(relativePath: { eq: "cards/5.png" }) {...cardFluidImage },
      img6: file(relativePath: { eq: "cards/6.png" }) {...cardFluidImage },
      img7: file(relativePath: { eq: "cards/7.png" }) {...cardFluidImage },
      img8: file(relativePath: { eq: "cards/8.png" }) {...cardFluidImage },
      img9: file(relativePath: { eq: "cards/9.png" }) {...cardFluidImage },
      img10: file(relativePath: { eq: "cards/10.png" }) {...cardFluidImage },
    }
  `)

  return (
    <section id="screenshots" className="container ">
      <div className="row">
        <div className="col-md-8 offset-md-2 col-12 pt-xs-100 pb-xs-100 text-center">
          <h2 className="text-uppercase">{title}</h2>
          <p>{sub}</p>
        </div>
      </div>

      <StyledRow className="row">
        <Card
          img={<Img alt={t('cards.card1.img_alt')} title={t('cards.card1.img_title')} fluid={img1.childImageSharp.fluid} />}
          title={t('cards.card1.title')}
          text={t('cards.card1.sub')}
        />
        <Card
          img={<Img alt={t('cards.card2.img_alt')} title={t('cards.card2.img_title')} fluid={img2.childImageSharp.fluid} />}
          title={t('cards.card2.title')}
          text={t('cards.card2.sub')}
        />
        <Card
          img={<Img alt={t('cards.card3.img_alt')} title={t('cards.card3.img_title')} fluid={img3.childImageSharp.fluid} />}
          title={t('cards.card3.title')}
          text={t('cards.card3.sub')}
        />
        <Card
          img={<Img alt={t('cards.card4.img_alt')} title={t('cards.card4.img_title')} fluid={img4.childImageSharp.fluid} />}
          title={t('cards.card4.title')}
          text={t('cards.card4.sub')}
        />
        <Card
          img={<Img alt={t('cards.card5.img_alt')} title={t('cards.card5.img_title')} fluid={img5.childImageSharp.fluid} />}
          title={t('cards.card5.title')}
          text={t('cards.card5.sub')}
        />
        <Card
          img={<Img alt={t('cards.card6.img_alt')} title={t('cards.card6.img_title')} fluid={img6.childImageSharp.fluid} />}
          title={t('cards.card6.title')}
          text={t('cards.card6.sub')}
        />
        <Card
          img={<Img alt={t('cards.card7.img_alt')} title={t('cards.card7.img_title')} fluid={img7.childImageSharp.fluid} />}
          title={t('cards.card7.title')}
          text={t('cards.card7.sub')}
        />
        <Card
          img={<Img alt={t('cards.card8.img_alt')} title={t('cards.card8.img_title')} fluid={img8.childImageSharp.fluid} />}
          title={t('cards.card8.title')}
          text={t('cards.card8.sub')}
        />
        <Card
          img={<Img alt={t('cards.card9.img_alt')} title={t('cards.card9.img_title')} fluid={img9.childImageSharp.fluid} />}
          title={t('cards.card9.title')}
          text={t('cards.card9.sub')}
        />
        <Card
          img={<Img alt={t('cards.card10.img_alt')}  title={t('cards.card10imgg_title')}fluid={img10.childImageSharp.fluid} />}
          title={t('cards.card10.title')}
          text={t('cards.card10.sub')}
        />
      </StyledRow>
    </section>
  )
}

const Card = ({ img, title, text }) => (
  <>
    <div className="img col-md-2 col-4 pb-xs-100">{img}</div>
    <div className="text col-md-4 col-8" style={{paddingRight: '20px'}}>
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
 </>
)


const StyledRow = styled.section`
  
`
export default Cards