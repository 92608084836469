import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"

export const personFluidImage = graphql`
  fragment personFluidImage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Person = ({ picture, name, description, small }) => (
  <div className={`col ${small ? 'col-md-3' : 'col-md-3'} col-12 text-center pt-xs-20 pb-xs-20`}>
    <div style={{
      maxWidth: `300px`,
      marginBottom: `1.45rem`,
      borderRadius: '100%',
      overflow: 'hidden',
      border: `${small? '5px' : '10px'} solid white`,
      margin: '0px auto 20px auto'
    }}>
      {picture}
    </div>
    <div >
      {/* <ul className="list-unstyled">
        <li className="list-inline-item"><a className="about_link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/obsidian.art/"><i className="fa fa-instagram"></i></a></li>
        <li className="list-inline-item"><a className="about_link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/obsidianart/"><i className="fa fa-linkedin"></i></a></li>
        <li className="list-inline-item"><a className="about_link" target="_blank" rel="noopener noreferrer" href="https://github.com/obsidianart"><i className="fa fa-github"></i></a></li>
        <li className="list-inline-item"><a className="about_link" target="_blank" rel="noopener noreferrer" href="https://www.obsidianart.com"><i className="fa fa-link"></i></a></li>
      </ul> */}
      <h3 className={`col ${small ? 'h4' : 'h3'}`}>{name}</h3>
      <h4 className={`col ${small ? 'h6' : 'h5'}`}>{description}</h4>
    </div>
  </div>
)


const About = ({ subtitle, t }) => {
  const { stefano, polina, marco, agnese, alessandra, jasmine, salvatore, francesco, anzhelika} = useStaticQuery(graphql`
    query {
      polina: file(relativePath: { eq: "about/polina-voropaeva.jpg" }) {...personFluidImage },
      stefano: file(relativePath: { eq: "about/stefano-solinas.jpg" }) {...personFluidImage },
      marco: file(relativePath: { eq: "about/marco.jpg" }) {...personFluidImage },
      agnese: file(relativePath: { eq: "about/agnese.jpg" }) {...personFluidImage },
      alessandra: file(relativePath: { eq: "about/alessandra.jpg" }) {...personFluidImage },
      jasmine: file(relativePath: { eq: "about/jasmine.jpg" }) {...personFluidImage },
      salvatore: file(relativePath: { eq: "about/salvatore.jpg" }) {...personFluidImage },
      francesco: file(relativePath: { eq: "about/francesco.jpg" }) {...personFluidImage },
      anzhelika: file(relativePath: { eq: "about/anzhelika.jpg" }) {...personFluidImage },
    }
  `)

  return (
    <StyledSections id="about" className="pt-xs-50 pb-xs-50">
      <div className="container">
        <div className="row text-center justify-content-around">
          <Person
            picture={<Img alt={t('people.stefano')} fluid={stefano.childImageSharp.fluid} />}
            name={t('people.stefano')}
            description={t('people.stefano_description')}
          />
          <Person
            picture={<Img alt={t('people.polina')} fluid={polina.childImageSharp.fluid} />}
            name={t('people.polina')}
            description={t('people.polina_description')}
          />
        </div>
        <div className="row text-center justify-content-around">
          <Person
            picture={<Img alt={t('people.jasmine')} fluid={jasmine.childImageSharp.fluid} />}
            name={t('people.jasmine')}
            description={t('people.jasmine_description')}
            small
          />
          <Person
            picture={<Img alt={t('people.alessandra')} fluid={alessandra.childImageSharp.fluid} />}
            name={t('people.alessandra')}
            description={t('people.alessandra_description')}
            small
          />
          <Person
            picture={<Img alt={t('people.marco')} fluid={marco.childImageSharp.fluid} />}
            name={t('people.marco')}
            description={t('people.marco_description')}
            small
          />
          <Person
            picture={<Img alt={t('people.agnese')} fluid={agnese.childImageSharp.fluid} />}
            name={t('people.agnese')}
            description={t('people.agnese_description')}
            small
          />
          <Person
            picture={<Img alt={t('people.anzhelika')} fluid={anzhelika.childImageSharp.fluid} />}
            name={t('people.anzhelika')}
            description={t('people.anzhelika_description')}
            small
          />
          <Person
            picture={<Img alt={t('people.francesco')} fluid={francesco.childImageSharp.fluid} />}
            name={t('people.francesco')}
            description={t('people.francesco_description')}
            small
          />
          <Person
            picture={<Img alt={t('people.salvatore')} fluid={salvatore.childImageSharp.fluid} />}
            name={t('people.salvatore')}
            description={t('people.salvatore_description')}
            small
          />
        </div>
      </div>
    </StyledSections>
  )
}

export default About


const StyledSections = styled.section`
  background-color: #232323;
  color: white;

  .rounded {
    border: 7px solid white;
  }

  h3,
  h4 {
    color: inherit;
  }

  .about_details {
    padding-top: 20px;
    padding-bottom: 100px;
  }

  .about_details ul li {
    display: inline-block;
    margin: 5px;
  }

  .about_link {
    font-size: 20px;
    background-color: white;
    padding: 14px 16px;
    display: inline-block;
    border-radius: 100%;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .about_link:hover {
    opacity: 0.5;
  }

`

