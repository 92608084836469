import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
// import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import Cards from "../components/Cards"
import Download from "../components/Download"
import About from "../components/About"



const IndexPage = ({ intl }) => {
  const t = (id) => intl.formatMessage({ id })
  return (
    <Layout>
      <SEO title={t("seo.homepage_title")} />

      <Hero t={t} subtitle={t('landing_hero')} />

      {/* <Cards
        t={t}
        title={t('cards.title')}
        sub={t('cards.sub')}
      />

      <Download
        t={t}
        title={t('download.title')}
        sub={t('download.sub')}
        text={t('download.text')}
      /> */}

      {/*
        // http://www.ellegirl.ru/articles/prilozhenie-dnya-igrushka-kotoraya-sdelaet-tebya-umnee-i-soobrazitelnee/
      */}

      {/* <About t={t} /> */}
    </Layout>
  )
}

export default injectIntl(IndexPage)
